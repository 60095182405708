import MainButton from "./MainButton"

export const ShowSecret = ({ text="", onClose=()=>{} }) => {
  const textHasEpochsUrl = (text.indexOf('GODMODEEPOCHS.COM') > -1);
  const textInUse = (textHasEpochsUrl) ? text.replace('GODMODEEPOCHS.COM', '') : text;
  return <div className="roll-secret">
    <div className="roll-secret--content fango-border">
      {textInUse}
      {(textHasEpochsUrl) ? <a href="https://dmstfctn.itch.io/godmode-epochs" target="_blank" style={{color: 'inherit'}}>𝗪𝗔𝗟𝗞 𝗧𝗛𝗥𝗢𝗨𝗚𝗛</a> : '' }
    </div>          
    <MainButton name="Close chronicle" onClick={onClose}/>
</div>
}