import { useState, useEffect } from "react";
import { useMUD } from "../MUDContext";

import { gameStates, getGameStateName, factionNames, secretStates } from "../config.js";

import { StateHome } from "./Game/States/StateHome";
import { StateRoll } from "./Game/States/StateRoll";
import { StateRead } from "./Game/States/StateRead";
import { StateWrite } from "./Game/States/StateWrite";
import { StateFork } from "./Game/States/StateFork";
import { StateInn } from "./Game/States/StateInn";
import { StateSelector } from "./Game/Persistent/StateSelector";
import { Guide } from "./Game/Persistent/Guide";
import { SecretGuide } from "./Game/Persistent/SecretGuide";
import { FangoUI } from "./Game/Persistent/FangoUI";
import { useLeaderboard } from "./LeaderboardContext";
import { ClockInterface } from "../components/Clock";
import { progressToADay } from "../utils";
import "../assets/styles/game.css";

import ExtraAudio from "../ExtraAudio.js";

import { useGuide } from "./GuideContext";
import { getGuideText, textConst } from "../text";

const getRanking = ( state, tables ) => {
  // const allPlayers = getRecords( tables.Player );
  const allStories = state.getRecords( tables.StoryPart);
  const listingTemp = {};
  for( const story in allStories ){
    const value = allStories[story].value;
    if( !listingTemp[value.author] ){
      listingTemp[value.author] = 0;
    }
    listingTemp[value.author] += value.posVotes - value.negVotes;
  }    
  const ranking = Object.values( listingTemp ).map( (value, index) => {
    const author = state.getValue( tables.Player, {key: Object.keys( listingTemp )[index]});
    const faction = state.getValue(tables.Fraction, {counterValue: author.fraction});
    return {
      author,
      faction: factionNames[faction.fractionName],
      votes: value
    };
  }).sort( (a,b) => {
    return b.votes - a.votes;
  });

  return ranking;
};

export const Game = () => {  
  const {
    network: { tables, useStore, playerEntity },
  } = useMUD();

  const [gameState, setGameState] = useState( gameStates.HOME );
  const [fork, setFork] = useState({
    hasFork: false,
    baseStory: {}
  });

  const {guideState, setGuideState} = useGuide();
  const {leaderboardState, setLeaderboardState} = useLeaderboard();
  const [secretState, setSecretState] = useState(0);
  const [showSecret, setShowSecret] = useState(false);

  useEffect(() => {
    const updateLeaderboardTimer = setInterval(() => {
      const r = getRanking( useStore.getState(), tables );
      setLeaderboardState({ranking: r});
    }, 1000);    
    return () => {
      clearInterval( updateLeaderboardTimer );
    }
  }, [])

  const timeouts = useStore( ({ getValue }) => {    
    return getValue(tables.PlayerTimes, {key: playerEntity});
  });

  const [now, setNow] = useState((new Date()).getTime());
  useEffect( () => {
    const handle = setInterval(()=>{
      setNow((new Date()).getTime());
    }, 1000)
    return () => {
      clearInterval( handle );
    }
  }, [now]);
  const player = useStore( ({ getValue }) => {    
    return getValue(tables.Player, {key: playerEntity});
  });
  const playerFaction = useStore( ({ getValue }) => {    
    const player = getValue(tables.Player, {key: playerEntity});
    const faction = getValue(tables.Fraction, {counterValue: player.fraction});
    return faction.fractionName;
  });

  useEffect(() => {
    console.log( 'GAME STATE CHANGE, to:', getGameStateName( gameState ) );
    ExtraAudio.setState( gameState );
  }, [gameState]) 

  return (
    <>
    {(gameState === gameStates.HOME) 
    
      ? <div className="player-create--fango-img player-create--fango-img__closer"></div> 
      : (gameState === gameStates.INN) ? <div className="player-create--fango-img player-create--fango-img__inn"></div> : ''
    }
    <article className={`game fango-grid game__state-${getGameStateName(gameState)}`}>
      <section className="game--play gi-ml">      
        <StateHome 
          shouldRender={gameState === gameStates.HOME}
        />
        <StateRoll 
          shouldRender={gameState === gameStates.ROLL}
          onRollComplete={ () => {        
            setGameState( gameStates.WRITE );
          } } 
        />
        <StateRead 
          shouldRender={gameState === gameStates.READ}
          onForkStory={ ( story ) => {
            setFork({
              hasFork: true,
              baseStory: story
            });
            setGameState( gameStates.FORK );
          }}
        />    
        <StateWrite 
          shouldRender={gameState === gameStates.WRITE}
          onWriteComplete={()=>{            
            setGameState( gameStates.HOME );           
            setGuideState({
              ...guideState,
              statement: getGuideText( playerFaction, textConst.WRITEPUBLISH, [{find: '<username>', replace: player.name }] ) 
            });
          }} 
        />
        <StateFork 
          shouldRender={gameState === gameStates.FORK}
          baseStory={ fork.baseStory }
          onForkComplete={()=>{
            setFork( {
              hasFork: false,
              baseStory: {}
            });
            setGameState( gameStates.READ );
          }}
          onClose={() => {
            setFork( {
              hasFork: false,
              baseStory: {}
            });
            setGameState( gameStates.READ );
          }}
        />
         <StateInn 
          shouldRender={gameState === gameStates.INN}
        />
      </section>
      <section className="game--interface gi-mr">
        <div className="gi-mr--pb">
          <FangoUI />
          <StateSelector      
            onSelect={setGameState}
          />
        </div>
        <div className="gi-mr--ps">
          <div className="game-interface-clocks">
            {(timeouts)
              ? <>       
                  {/* <ClockInterface name="Library Timeout" progress={progressToADay( now, parseInt( timeouts.lastRoll ) * 1000)}/> */}
                </>
              : ''
            }
          </div>
        </div>
        <div className="gi-mr--gb">

        </div>
        <div className="gi-mr--g">
          <Guide/>
          <SecretGuide />        
        </div>
      </section>    
    </article>
  </>
  )
}