import { useMUD } from "../MUDContext";
import { formatMultilineForDisplay } from "../utils";

import "../assets/styles/seed-viewer.css";

export const SeedViewer = ( {seedId} ) => {
  const {
    network: { tables, useStore },    
  } = useMUD();
  const seed = useStore( ({getRecord}) => {
    const seed = getRecord( tables.StorySeed, {counterValue: seedId});
    return seed.value;
  })
  return <>
    <div className="seed-viewer">
      <h1 className="seed-number">
        Chronicle {seed.seed}:
      </h1>
      <div className="seed-content">
        {(seed.data.length <= 0) ? '    ' : formatMultilineForDisplay(seed.data)}
      </div>
    </div>
  </>
}