import { useState, useEffect } from "react";
import { useMUD } from "../../../MUDContext"
import { useGuide } from "../../GuideContext";

import { ReactTyped } from "react-typed";

import MainButton from "../../../components/MainButton";

import ExtraAudio from "../../../ExtraAudio.js";

import "../../../assets/styles/fango-guide.css";

import imgGuideChurch from "../../../assets/images/guide-Church.gif";
import imgGuideRadical from "../../../assets/images/guide-Radical.gif";
import imgGuideWolf from "../../../assets/images/guide-Wolf.gif";

import ButtonStyleText from "../../../components/ButtonStyleText";



export const GuideDisplay = ({style, faction, statement='', onStatementComplete=()=>{}}) => {
  const img = {
    Church: imgGuideChurch,
    Radical: imgGuideRadical,
    Wolf: imgGuideWolf
  } 

  const names = {
    Church: "Penitenzio:",
    Radical: "Old mentor:",
    Wolf: "A voice in your head:"
  }

  const [stateStatement, setStateStatement] = useState( '' );
  const [textIndex, setTextIndex] = useState(0);
  const [showNext, setShowNext] = useState( false );

  useEffect( () => {
    if( statement !== stateStatement ){
      setStateStatement( statement );
      setTextIndex( 0 );
      if( statement !== '' ){
        ExtraAudio.sfxWhisperStart();
      } else {
        ExtraAudio.sfxWhisperEnd();
      }
    }
  }, [statement]);
 
  return <div className="fango-guide" style={style}>
    <ButtonStyleText text={names[faction]} className="fango-guide--name" />
    {(showNext) 
      ? 
        <MainButton 
          className="fango-guide--next"
          name="Next"
          onClick={()=>{            
            if( textIndex < stateStatement.length - 1 ){
              setTextIndex( textIndex + 1 );
              ExtraAudio.sfxWhisperStart();
            } else {              
              onStatementComplete();
              ExtraAudio.sfxWhisperEnd();
            }
          }} 
        /> 
      : 
        '' 
    }
    <div className="fango-guide--box">
      <div className="fango-guide--avatar">
        <img src={img[faction]} />
      </div>
      <div className="fango-guide--text">
        <div>
          <ReactTyped
            strings={ Array.isArray(stateStatement) ? [ stateStatement[textIndex] ] : [stateStatement] }
            typeSpeed={5}
            showCursor={false}
            onStart={()=>{}}
            onComplete={()=>{
              ExtraAudio.sfxWhisperEnd();
              if( Array.isArray(stateStatement) && textIndex < stateStatement.length - 1 ){                
                setShowNext( true );
              } else {
                setShowNext( false );                
                onStatementComplete();
              }
            }}
          />
        </div>
      </div>
    </div>
  </div>
}

export const Guide = ({style={}, onStatementComplete=()=>{}}) => { 
  const {
    network: { tables, useStore, playerEntity }
  } = useMUD();

  const { guideState, setGuideState } = useGuide();  

  const faction = useStore( ({getValue}) => {
    const player = getValue( tables.Player, {key: playerEntity} );
    const faction = getValue(tables.Fraction, {counterValue: player?.fraction });    
    return faction.fractionName;
  })  

  return <GuideDisplay 
    style={style}
    faction={faction}
    statement={guideState.statement}
    onStatementComplete={()=>{ onStatementComplete() }}
  />
}