import { useMUD } from "../../../MUDContext";
import ButtonStyleText from "../../../components/ButtonStyleText";
import { factionNames } from "../../../config";

export const FangoUI = () => {
  const {
    network: { tables, useStore, playerEntity },
    systemCalls: { addPlayer },
  } = useMUD();

  // const factionNames = {
  //   "Church": "monk",
  //   "Radical": "radical",
  //   "Wolf": "fool"
  // }

  const hasPlayer = useStore( ({getValue}) => {
    const player = getValue(tables.Player, {key: playerEntity}); 
    return !!player;
  });

  const player = useStore(({getValue}) => {
    const player = getValue(tables.Player, {key: playerEntity}); 
    if( player ){
      const faction = getValue(tables.Fraction, {counterValue: player.fraction});

      return {
        name: player.name,
        faction: faction.fractionName 
      }
    } else {
      return {
        name: '',
        faction: ''
      }
    }
  });



  return <>
    <ButtonStyleText text={`${player.name}, a ${factionNames[player.faction]}`} />      
  </>
}