import { createContext, useContext } from "react";

const LeaderboardContext = createContext({});

export const LeaderboardProvider = ({ children, value }) => {  
  return <LeaderboardContext.Provider value={value}>{children}</LeaderboardContext.Provider>;
};

export const useLeaderboard = () => {
  const value = useContext(LeaderboardContext);  
  if (!value) throw new Error("Must be used within a LeaderboardProvider");
  return value;
};
