import loop0 from './assets/audio/music/loop0-drumbell.mp3';
import loop0lowpass from './assets/audio/music/loop0-lowpass.mp3';
import loop1 from './assets/audio/music/loop1-flute.mp3';
import loop1lowpass from './assets/audio/music/loop1-lowpass.mp3';
import loop2 from './assets/audio/music/loop2-chord.mp3';
import loop3 from './assets/audio/music/loop3-oi.mp3';
import loop4 from './assets/audio/music/loop4-bell.mp3';
import loop5a from './assets/audio/music/loop5a-drumsonly.mp3';
import loop5b from './assets/audio/music/loop5b-nochord.mp3';
import loop6 from './assets/audio/music/loop6-pipe.mp3';
import loop7 from './assets/audio/music/loop7-pipechord.mp3';
import loop8 from './assets/audio/music/loop8-oipipechord.mp3';
import loop9 from './assets/audio/music/loop9-chordbelldrums.mp3';
import loopsilence from './assets/audio/music/loop-silence.mp3';
import loop6interrupt from './assets/audio/music/loop6-interrupt.mp3';

const IS_SERVER = false;

const gameStates = { 
  'HOME': 0,
  'ROLL': 1,
  'READ': 2,
  'WRITE': 3,
  'FORK': 4,
  'INN': 5,
  'INTRO': 100,
  'FACTION': 200,
  'NAME': 300
}

const factionNames = {
  "Church": "monk",
  "Radical": "scholar",
  "Wolf": "fool"
}

const audioCfg = {
  files: {
    loop0, 
    loop0lowpass,
    loop1,
    loop1lowpass,
    loop2,
    loop3,
    loop4,
    loop5a,
    loop5b,
    loop6,
    loop7,
    loop8,
    loop9,
    loopsilence,
    loop6interrupt,
  },
  states: {}
}

audioCfg.states[gameStates.INTRO] = {
  tracks: ['loop0'], 
  direction: 1
}
audioCfg.states[gameStates.FACTION] = {
  tracks: ['loop1'],
  direction: 1
}
audioCfg.states[gameStates.NAME] = {
  tracks: ['loop2', 'loop3'],
  direction: 1
}
audioCfg.states[gameStates.HOME] = {
  tracks: ['loop3', 'loop5b'],
  ambience: 'squareDistant', 
  direction: 1
}
audioCfg.states[gameStates.READ] = { /* ambient square sound */
  tracks: ['loopsilence', 'loop0', 'loop9', 'loop9', 'loop4', 'loop4', 'loop2', 'loopsilence', 'loopsilence', 'loop5a', 'loop5b', 'loop1', 'loop4', 'loop4', 'loopsilence', 'loopsilence', 'loop0', 'loopsilence', 'loop5a', 'loop9', 'loop9', 'loop4', 'loop3', 'loopsilence', 'loopsilence' ],
  ambience: 'square',
  direction: 1
}
audioCfg.states[gameStates.ROLL] = {
  tracks: ['loop0lowpass', 'loop0lowpass', 'loopsilence', 'loopsilence'], /* inside library: lowpass loops and ambient square sounds */
  ambience: 'squareLowpass',
  direction: 1
}
audioCfg.states[gameStates.WRITE] = {
  tracks: ['loop1lowpass', 'loop1lowpass', 'loopsilence', 'loopsilence', 'loop0lowpass', 'loop0lowpass', 'loopsilence', 'loopsilence'], /* inside library: low pass loops inside the library and/or ambient square sounds */
  ambience: 'squareLowpass',
  direction: 1
}
audioCfg.states[gameStates.FORK] = { /* ambient square sound */
  tracks: ['loop1', 'loopsilence', 'loopsilence', 'loop5b'],
  ambience: 'square',
  direction: 1
}
audioCfg.states[gameStates.INN] = { /*inn ambient sound */
  tracks: ['loopsilence', 'loop6interrupt',  'loop6', 'loop7', 'loopsilence', 'loop8', 'loop8', 'loop6', 'loopsilence', 'loopsilence','loop5a', 'loopsilence', 'loop5a', 'loop7', 'loopsilence' ],
  ambience: 'inn',
  direction: 1
}


const getGameStateName = ( state ) => {
  const keys = Object.keys( gameStates );
  const values = Object.values( gameStates );
  return keys[ values.indexOf( state )];
}

const secretCfg = {
  pw1: 'mud',
  pw2: 'eat',
  pw3: '',
  texts: [
`████ ██:
"Tired of life in Fango yet?
Tired of walking through mud? Throwing mud? Eating mud?
Tired of the monks, the fools, the scholars and their senseless debates?

Keep reading.."
`,
`████ ██:
"We know you are curious. You want to see the machine.
You want to touch the machine. You want to 𝘧𝘦𝘦𝘭 the machine.

You can become one with the machine.. Keep reading.."
`,
`████ ██:
"We have created the machine. And we are here to rescue you.

All we ask in return, is a 𝘭𝘪𝘵𝘵𝘭𝘦 𝘣𝘪𝘵 𝘰𝘧 𝘺𝘰𝘶𝘳 𝘵𝘪𝘮𝘦 to train the machine. The machine has many important things to do.

Walk through this portal, and you will be removed from Fango.
You will agree to your liberation, and you will agree to train the machine."

GODMODEEPOCHS.COM`
  ],
  symbols: [
    'm', 
    'u', 
    'd'
  ]
}

const secretStates = {
  ZERO: 0,
  ONE: 1,
  TWO: 2,
  THREE: 3
}

const getSecretStateName = ( state ) => {
  const keys = Object.keys( secretStates );
  const values = Object.values( secretStates );
  return keys[ values.indexOf( state )];
}

export {  
  IS_SERVER,
  gameStates,
  factionNames,
  audioCfg,
  secretCfg,
  secretStates,
  getGameStateName,
  getSecretStateName
}