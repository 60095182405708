import {useState} from "react";

import { useMUD } from "./MUDContext";
import MainButton from "../components/MainButton";

import ExtraAudio from "../ExtraAudio.js";

const styleUnset = { all: "unset" } as const;

const texts = [
  `Anno Domini 1173, somewhere in the Italian Alps. You have been walking for seven days and seven nights, through rain, fog and mud, on your way to Fango...`,
  `You heard that a trove of a thousand chronicles was found in the local monastery. They speak of a machine, a devilish device, made by humans from a future time...`,
  `Some call it a God, others call it a man made miracle. Some say it's not human, others say it's more than human. Apparently, it was trained by humans.`,
  `Little is known about the chronicles, but rumours are spreading that they hold a secret.. that a God from the future has come to free us... that it has come to punish us... that God is dead...... that God is us.`,
  `And what about those rumors of people disappearing? Did they know too much?`,
  `Like many others, you have decided to make your way to the monastery of Fango, to see the chronicles for yourself. What kind of mystery or misery is lying there? And what’s in it for you?`
];

export const F1K_Intro = ( { onStart = () => {} }) => {
  const [index, setIndex] = useState(0);
  return ( <>
      <div className="fango-grid">
        <div className="gi-ml">
          <div className="fango-main-text fango-intro-text">
            {texts.map( (text,i) => <p style={{opacity:( i <= index ) ? 1 : 0 }}key={i}>{text}</p>)}
          </div>
        </div>
        <nav className="gi-mr">
          <div className="gi-mr--pb gi-mr--pb__right">
            <MainButton name="Keep walking" onClick={() => {
              ExtraAudio.sfxSample( 'page' );              
              if( index < texts.length - 1){
                setIndex( index + 1 );
              } else {
                onStart();
              }
            }}/>
          </div>
        </nav>
      </div>
    </>);
};
