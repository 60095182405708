import { useState, useEffect } from "react";
import { useMUD } from "../MUDContext";
import { PlayerCreation } from "./PlayerCreation";
import { F1K_Intro } from "./Intro";
import MainButton from "../components/MainButton"
import {PopUp} from "../components/PopUp"
import { Game } from "./Game";
import { GuideProvider } from "./GuideContext"
import { LeaderboardProvider } from "./LeaderboardContext"
import { gameStates } from "../config.js";

import ExtraAudio from "../ExtraAudio.js";

import imgSoundOn from "../assets/images/soundon.png";
import imgSoundOff from "../assets/images/soundoff.png";

window.ExtraAudio = ExtraAudio;

export const Wrapper = () => {
  const {
    network: { tables, useStore, playerEntity },
  } = useMUD();
  
  const [guideState, setGuideState] = useState({
    statement: ''
  });  

  const [leaderboardState, setLeaderboardState] = useState({
    ranking: []
  });  

  const [showCredits, setShowCredits] = useState( false );

  const [isMuted, setIsMuted] = useState( false );
  ExtraAudio.onChangeMute = () => {
    setIsMuted( ExtraAudio.muted );
  }

  const [isLanding, setIsLanding] = useState( true );
  const [isIntro, setIsIntro] = useState( false );

  const player = useStore( ({ getValue }) => {    
    return getValue(tables.Player, {key: playerEntity});
  });
  
  return <>
    <LeaderboardProvider value={{ leaderboardState, setLeaderboardState }}>
    <div className="fango-base">
      <header className="fango-base--header">     
        <h1 
          className="fango-border-text fango-border-text__greyed fango-main-title" 
          onClick={() => { 
            ExtraAudio.sfxSample('click');
            setShowCredits(true); 
          }}>
          <span>Fango 1000</span>
        </h1>
        <div className="fango-restart">
          {isLanding 
            ? <MainButton 
                className="btn-start-game"
                name="Click to start" 
                onClick={() => {                  
                  ExtraAudio.start();
                  setIsLanding( false );
                  setIsIntro( true );
                }}
              />
            : <MainButton 
                name="Click to restart" 
                onClick={() => {
                  localStorage.clear()
                  window.location = window.location.href;
                }}
              />
          }          
        </div>
        <div className="fango-mute">
          {!isLanding ? <button 
            onClick={() => {
              if( ExtraAudio.muted ){
                ExtraAudio.unmute();
                ExtraAudio.sfxSample('click');
              } else {
                ExtraAudio.mute();
              }
            }} 
            className={`fango-main-button fango-mute-button ${(isMuted) ? 'muted' : 'unmuted'}`}
            type="button"
            style={{
              backgroundImage: (isMuted ) ? imgSoundOff : imgSoundOn
            }}
          >
            <span className="text">{(isMuted) ? 'unmute' : 'mute'}</span>
          </button> : '' }
        </div>
      </header>
      { !isLanding
        ? <GuideProvider value={{ guideState, setGuideState }}>
            { isIntro
              ? 
                <F1K_Intro onStart={() => { setIsIntro( false ) }}/>
              : player && !isLanding
                ? <Game /> 
                : <PlayerCreation />
            }
          </GuideProvider>
        : <div className="fango-landing-image">
          
        </div>
      }
      { (showCredits) 
        ? 
          <PopUp 
            className="fango-credits" 
            onClick={ () => {
              setShowCredits( false ) 
            }}
          >
            <div>
              <p style={{fontWeight: 'bold', fontStyle: 'italic'}}>Fango 1000 (beta)</p>
              <p>an onchain narrative game by dmstfctn</p>
              <p>
                <br/>Writing, development, design: dmstfctn<br/>
                Additional writing: Mina Amiri Kalvøy<br/>
                Soundtrack: Hero Image<br/>
                Illustrations: Carlo Crivelli (†1495) and dmstfctn<br/>
                MUD and solidity development: Paul Seidler<br/>
                <br/>
                Supported by: AW House, Baltan Laboratories, Etopia, Mask Network, MEET Digital Culture Center.
                <br/><br/><br/>
                <span style={{fontStyle: 'italic'}}>Fango 1000</span> is a prototype of a <a href="https://aw.network/posts/large-lore-models" target="_blank" rel="noopener noreferrer">Large Lore Model</a>, a tool hypothesised by dmstfctn, Eva Jäger and Alasdair Milne to connect existing onchain data with user stories and perspectives. The chronicles in <span style={{fontStyle: 'italic'}}>Fango 1000</span> contain a historical record of actions generated by players of dmstfctn's AI training game <span style={{fontStyle: 'italic'}}>Godmode Epochs</span> (2023), which are here turned into material to use for stories.
              </p>
            </div>
          </PopUp> 
        : ''
      }
    </div>
    </LeaderboardProvider>
  </>
}