import ExtraAudio from "../ExtraAudio.js";

export default function MainButton({name, disabled=false, onClick=()=>{}, className='', type="button"}) {
    return (
        <button 
            onClick={(e) => {
                ExtraAudio.sfxSample('click');
                onClick(e);
            }} 
            disabled={disabled} 
            className={`fango-main-button ${className}`}
            type={type}
        >
            {name}
        </button>
    );
}