import * as Tone from 'tone';
import {audioCfg, gameStates} from './config.js';

import whisper from './assets/audio/whisper/whisper-fulllen.mp3';

import ambientSquare from './assets/audio/sfx/square-ambient.mp3';
import ambientSquareLowpass from './assets/audio/sfx/square-ambient-lowpass.mp3';
import ambientSquareDistant from './assets/audio/sfx/square-ambient-distant.mp3';
import ambientInn from './assets/audio/sfx/inn-ambient.mp3';

import sfxBellaVoice from './assets/audio/sfx/bella-voice.mp3';
import sfxBravoVoice from './assets/audio/sfx/bravo.mp3';
import sfxBrillianteVoice from './assets/audio/sfx/brilliante.mp3';
import sfxGuildVoice from './assets/audio/sfx/gild-voice.mp3';
import sfxGoldoneVoice from './assets/audio/sfx/goldone-voice.mp3';

import sfxBuryVoice from './assets/audio/sfx/bury-voice.mp3';
import sfxFangoVoice from './assets/audio/sfx/fango.mp3';
import sfxMerdaVoice from './assets/audio/sfx/merda.mp3';
import sfxMudVoice from './assets/audio/sfx/mud-voice.mp3';
import sfxThrowVoice from './assets/audio/sfx/throw-voice.mp3';

import sfxMud from './assets/audio/sfx/mud.mp3';
import sfxPage from './assets/audio/sfx/page.mp3';
import sfxPin from './assets/audio/sfx/pin.mp3';
import sfxClick from './assets/audio/sfx/click.mp3';
import sfxDrink  from './assets/audio/sfx/drink.mp3';
import sfxGild  from './assets/audio/sfx/gilding-fx.mp3';

const SFX_SPEC = {
    'click': sfxClick,
    'mud-sfx': sfxMud,
    'pin': sfxPin,
    'page': sfxPage,
    'drink': sfxDrink,
    'gild': sfxGild,
    'bella': sfxBellaVoice, 
    'bravo': sfxBravoVoice, 
    'brilliante': sfxBrillianteVoice, 
    'gild-it': sfxGuildVoice, 
    'goldone': sfxGoldoneVoice,
    'bury-it': sfxBuryVoice, 
    'fango': sfxFangoVoice, 
    'merda': sfxMerdaVoice, 
    'mud': sfxMudVoice, 
    'throw-it': sfxThrowVoice
}

const ExtraAudio = function(){
  this.Tone = Tone;
  this.toneStarted = false;
  this.fxCreated = false;
  this.muted = false;
  this.gameState = gameStates.INTRO;
  this.defineMusic();
  this.defineSfx();
}

ExtraAudio.prototype = {
  getAudioContext: function(){
      return Tone.getContext().rawContext;
  },
  mute: function(){
      this.muted = true;
      this.musicOut.disconnect();
      this._onChangeMute();
  },
  unmute: function(){
      this.muted = false; 
      this.musicOut.toDestination();
      this._onChangeMute();
  },
  start: async function(){      
      if( this.toneStarted ) return      
      await Tone.start();
      //console.log('tone started');
      this.toneStarted = true;
      this.createFX();
      this.setupMusic();
      this.setupSfx();
  },
  createFX: function(){
      if( this.fxCreated ) return;
      this.fShift = new Tone.FrequencyShifter( 250 );
      this.vib = new Tone.Vibrato( 7, 0.5 );
      this.rev = new Tone.Reverb({
          decay: 0.2,
          preDelay: 0.05,
          wet: 0.75
      });
      this.rev2 = new Tone.Reverb({
          decay: 1.5,
          preDelay: 0.2,
          wet: 0.1
      });      
      this.filt = new Tone.Filter( 8000, "lowpass" );
      this.fxCreated = true;
  },
  defineMusic: function(){
      //console.log('defineMusic()');
      this.playersDef = audioCfg.files;
     
      this.musicTriggerNextTrack = false;
      this.tracks = {};
      this.sequence = audioCfg.states[this.gameState].tracks;
      this.sequenceIndex = 0;
      this.sequenceDirection = audioCfg.states[this.gameState].direction;
      this.musicCurrentTrack = this.sequence[ this.sequenceIndex ];
      this.musicNextTrack = 0;
      this.musicIsScheduledEnd = false;
      this.musicIsPlayingEnd = false;
  },
  loadMusic: function(){
      //console.log('loadMusic()');

      Object.keys( this.playersDef ).forEach( ( key, i ) => {
          this.tracks[key] = {
              name: key,
              player: new Tone.Player( this.playersDef[key] ),
              channel: new Tone.Channel( {volume: -100 })
          };
          //console.log('created player: ', key)
      });

      this.musicOut = new Tone.Channel( {volume: (this.muted) ? -100 : 0  })

  },
  setState: function( state ){
    console.log( 'ExtraAudio.setState(', state, ')' );
    this.gameState = state;
    const nextMusicSequence = audioCfg.states[ this.gameState ];
    this.musicFadeToSequence( nextMusicSequence.tracks, nextMusicSequence.direction );
    if( audioCfg.states[ this.gameState ].ambience ){
        this.sfxAmbienceStart( audioCfg.states[ this.gameState ].ambience );
    } else {
        this.sfxAmbienceEnd();
    }
  },
  setMusicEvents: function(){
      const getNextStageMusic = () => {
          //return (Object.keys( epoch.exploits ).length > 0 && epoch.progress.stage !== 6 ) ? CFG.stages.musicPostExploit : CFG.stages.music[ epoch.progress.stage ];
          return audioCfg.states[ this.gameState ];
      }
      // eventsCenter.on('intro-complete', () => {
      //     Tone.loaded().then(()=>{ 
      //         this.startMusic();
      //     });
      // });
      // eventsCenter.on('game-over', () => {
      //     this.musicScheduleEnd();
      // });
      // eventsCenter.on('next-stage', ({stage}) => {
      //     const nextMusicSequence =  getNextStageMusic();
      //     this.musicRestartSequence( nextMusicSequence.tracks, nextMusicSequence.direction );
      // });
      // eventsCenter.on('brainspace-pre-visible', () => {
      //     Tone.getTransport().position = "0:0:0";
      //     this.musicCutToSequence( CFG.memory.music.tracks, CFG.memory.music.direction );
      // });
      // eventsCenter.on('brainspace-pre-hidden', () => {
      //     const nextMusicSequence = getNextStageMusic();
      //     this.musicFadeToSequence( nextMusicSequence.tracks, nextMusicSequence.direction );
      // });
  },
  setupMusic: function(){
      //console.log('setupMusic()');
      
      this.setMusicEvents();

      this.loadMusic();
      
      const nextLoop = () => {
          if( this.musicIsScheduledEnd ) return;
          if( this.sequence.length > 1 ){
              //console.log('move to next track in sequence')
              this.sequenceIndex = (this.sequenceIndex + this.sequenceDirection < this.sequence.length && this.sequenceIndex + this.sequenceDirection >= 0 ) 
                                      ? this.sequenceIndex + this.sequenceDirection 
                                      : ( this.sequenceDirection > 0 ) ? 0 : this.sequence.length - 1;
              this.musicNextTrack = this.sequence[ this.sequenceIndex ];
              this.musicCrossfadeTracks( "2m", "+0" );
              this.musicCurrentTrack = this.musicNextTrack;
          }
      }
      const endAndStop = () => {
          if( this.musicIsPlayingEnd ) return;
          this.musicIsPlayingEnd = true;
          Tone.getTransport().loop = false;
          Tone.getTransport().position = "0:0:0";
          this.musicNextTrack = 'endloop';
          this.musicCrossfadeTracks( "2m", "+0" );
          //console.log('play end track');
      }

      Tone.getTransport().scheduleRepeat((time) => {
          if( this.musicIsScheduledEnd ){
              endAndStop();
              return;
          }
          //console.log('Tone.getTransport() loop point')
          if( this.musicTriggerNextTrack ){
              //console.log('trigger new track')
              // use the callback time to schedule events            
              this.musicCrossfadeTracks( "2m", "+2m" );
              this.musicCurrentTrack = this.musicNextTrack;
              this.musicTriggerNextTrack = false;
          }
      }, "4m");
      Tone.getTransport().schedule((time) => {
          if( this.musicIsScheduledEnd ) return;
      }, "0m" );
      Tone.getTransport().schedule((time) => {
          if( this.musicIsScheduledEnd ) return;
          //console.log('Tone.getTransport() schedule 6m')
          nextLoop();
      }, "6m" );
      Tone.getTransport().schedule((time) => {
          if( this.musicIsScheduledEnd ) return;
          //console.log('Tone.getTransport() schedule 14m')
          nextLoop();
      }, "14m" );
      Tone.getTransport().schedule((time) => {
          if( this.musicIsScheduledEnd ) return;
          //console.log('Tone.getTransport() schedule 22m')
          nextLoop();
      }, "22m" );
      Tone.getTransport().schedule((time) => {
          if( this.musicIsScheduledEnd ) return;
          //console.log('Tone.getTransport() schedule 30m')
          nextLoop();
      }, "30m" );
      
      Tone.getTransport().bpm.value = 121.07;
      Tone.getTransport().setLoopPoints(0, "32m");
      Tone.getTransport().loop = true;

      Object.keys( this.playersDef ).forEach( ( key, i ) => {
          this.tracks[key].player.sync().start(0).connect( this.tracks[key].channel );
          //this.tracks[key].channel.toDestination();
          this.tracks[key].channel.connect( this.musicOut );
          //console.log('connected player: ', key );
      });

      this.musicOut.toDestination();

      Tone.loaded().then(()=>{ 
          this.startMusic();
      });

  },
  musicFadeOut: function( duration ){
      Tone.getDestination().volume.linearRampTo( -100, duration )
  },
  musicFadeIn: function( duration ){
      Tone.getDestination().volume.linearRampTo( 0, duration )
  },
  musicCrossfadeTracks: function( duration, _delay ){
      const delay = _delay || 0;
      const current = this.tracks[ this.musicCurrentTrack ];
      const next = this.tracks[ this.musicNextTrack ];
      //console.log('from:', current.name, 'to: ', next.name, 'duration: ', duration, 'delay: ', delay )
      current.channel.volume.linearRampTo( -100, duration, delay );
      next.channel.volume.linearRampTo( 0, duration, delay );
  },
  startMusic: function(){
      //console.log('startMusic(): play ', this.tracks[ this.musicCurrentTrack ] );
      this.tracks[ this.musicCurrentTrack ].channel.volume.value = 0;
      Tone.getTransport().start();
  },
  musicSetSequence: function( sequence, direction ){
      //console.log('musicSetSequence() to: ', sequence )
      this.sequence = sequence;
      this.sequenceDirection = direction || 1;
  },
  musicRestartSequence: function( sequence, direction ){
      if( sequence ){
          this.musicSetSequence( sequence, direction );
      }
      this.musicTriggerNextTrack = true;
      this.sequenceIndex = 0;
      this.musicNextTrack = this.sequence[ this.sequenceIndex ];
  },
  musicCutToSequence: function( sequence, direction ){
      if( sequence ){
          this.musicSetSequence( sequence, direction );
      }
      this.sequenceIndex = 0;
      this.musicNextTrack = this.sequence[ this.sequenceIndex ];
      this.loopsSinceLastSequenceSwitch = 0;
      this.musicCrossfadeTracks( 0.05, "+0" );
      this.musicCurrentTrack = this.musicNextTrack;
  },
  musicFadeToSequence: function( sequence, direction ){
      if( sequence ){
          this.musicSetSequence( sequence, direction );
      }
      this.sequenceIndex = 0;
      this.musicNextTrack = this.sequence[ this.sequenceIndex ];
      this.loopsSinceLastSequenceSwitch = 0;
      this.musicCrossfadeTracks( "2m", "+0" );
      this.musicCurrentTrack = this.musicNextTrack;
  },
  musicScheduleEnd: function(){
      this.musicIsScheduledEnd = true;
  },
  defineSfx: function(){
    this.sfx = {        
        samp: {},
        amb: {
            square: {},
            squareLowpass: {},
            squareDistant: {},
            inn: {}
        },
        voice: {
            whisper: {},
        }
    };      
  },
  setupSfx: function(){
    //whisper
    this.sfx.voice.whisper = {
        player: new Tone.Player( whisper ),
        channel: new Tone.Channel({ volume: -100 })
    }    
    this.sfx.voice.whisper.player.sync().start(0).connect( this.sfx.voice.whisper.channel );  
    this.sfx.voice.whisper.channel.connect( this.musicOut );

    //ambience
    this.sfx.amb.square = {
        player: new Tone.Player( ambientSquare ),
        channel: new Tone.Channel({ volume: -100 })
    }
    this.sfx.amb.square.player.sync().start(0).connect( this.sfx.amb.square.channel );  
    this.sfx.amb.square.channel.connect( this.musicOut );

    this.sfx.amb.squareLowpass = {
        player: new Tone.Player( ambientSquareLowpass ),
        channel: new Tone.Channel({ volume: -100 })
    }
    this.sfx.amb.squareLowpass.player.sync().start(0).connect( this.sfx.amb.squareLowpass.channel );  
    this.sfx.amb.squareLowpass.channel.connect( this.musicOut );

    this.sfx.amb.squareDistant = {
        player: new Tone.Player( ambientSquareDistant ),
        channel: new Tone.Channel({ volume: -100 })
    }
    this.sfx.amb.squareDistant.player.sync().start(0).connect( this.sfx.amb.squareDistant.channel );  
    this.sfx.amb.squareDistant.channel.connect( this.musicOut );

    this.sfx.amb.inn = {
        player: new Tone.Player( ambientInn ),
        channel: new Tone.Channel({ volume: -100 })
    }
    this.sfx.amb.inn.player.sync().start(0).connect( this.sfx.amb.inn.channel );  
    this.sfx.amb.inn.channel.connect( this.musicOut );

    //oneshots
    for( let k in SFX_SPEC ){
        const sound = SFX_SPEC[k]
        this.sfx.samp[k] = new Tone.Player( sound );
        this.sfx.samp[k].connect( this.musicOut );
    }
  },
  sfxWhisperStart: function(){
    this.sfx.voice.whisper.channel.volume.linearRampTo( 0, 0.2 );
  },
  sfxWhisperEnd: function(){
    this.sfx.voice.whisper.channel.volume.linearRampTo( -100, 0.5 );
  },
  sfxAmbienceStart: function( which ){
    for( let k in this.sfx.amb ){
        if( k === which ){
            this.sfx.amb[k].channel.volume.linearRampTo( 0, 0.2 );
        } else {
            this.sfx.amb[k].channel.volume.linearRampTo( -100, 0.5 );
        }
    }
   },
  sfxAmbienceEnd: function(){
    for( let k in this.sfx.amb ){
        this.sfx.amb[k].channel.volume.linearRampTo( -100, 0.5 );
    }
  },
  sfxSample: function( name ){
    if( !this.sfx.samp[name] ) return;
    this.sfx.samp[name].start()
  },  
  onChangeMute: function(){},
  _onChangeMute: function(){
    if( typeof this.onChangeMute === 'function' ){
        this.onChangeMute();
    }
  },
}

export default new ExtraAudio();