import { useState, useEffect } from "react";
import { useMUD } from "../../../MUDContext"

import { ReactTyped } from "react-typed";

import MainButton from "../../../components/MainButton";
import ButtonStyleText from "../../../components/ButtonStyleText";

import { getGuideText, textConst } from "../../../text";

import imgMachine from "../../../assets/images/guide-machine.gif";

export const SecretGuide = () => { 
  const {
    network: { tables, useStore, playerEntity }
  } = useMUD();

  const [secretState, setSecretState] = useState(0);
  const [showState, setShowState] = useState( false );
  const [statement, setStatement] = useState('');

  const faction = useStore( ({getValue}) => {
    const player = getValue( tables.Player, {key: playerEntity} );
    const faction = getValue(tables.Fraction, {counterValue: player?.fraction });    
    return faction.fractionName;
  })  

  const player = useStore( ({getValue}) => {
    const player = getValue( tables.Player, {key: playerEntity} );
    return player;
  })

  useEffect( () => {    
    if( player.secret > 0 && player.secret !== secretState ){
      setSecretState( player.secret );
      setShowState( true );
      const TCONST = ( player.secret === 1 ) 
        ? textConst.SECRET_ONE
        : (player.secret === 2 )
          ? textConst.SECRET_TWO
          : (player.secret === 3 )
            ? textConst.SECRET_THREE
            : false;
      if( TCONST ){
        setStatement( getGuideText( faction, TCONST ) );
      }   
    }
  }, [player] );

  if( player.secret === 0 ){
    return '';
  }

  return (showState) 
    ? <div className="secret-guide-image fango-guide">
        <ButtonStyleText text={'..God? Devil? Machine?'} className="fango-guide--name" />
        <MainButton 
          className="fango-guide--next"
          name="Acknowledge"
          onClick={()=>{            
            setShowState( false );
          }} 
        /> 
        <div class="fango-guide--box">
          <div class="fango-guide--avatar">
            <img src={imgMachine} />
          </div>  
          <div className="fango-guide--text">
              <div>
                <ReactTyped
                  strings={ [statement] }
                  typeSpeed={5}
                  showCursor={false}
                  onStart={()=>{}}
                  onComplete={()=>{
                    
                  }}
                />
              </div>
            </div>           
        </div>
      </div> 
    : '';
}