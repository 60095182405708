import { useMUD } from "./MUDContext";
import { Wrapper } from "./scenes/Wrapper";

import ExtraAudio from "./ExtraAudio.js";

export const App = () => {
  const {
    network: { tables, useStore },
    systemCalls: { addPlayer },
  } = useMUD();

  return (
    <>
      <Wrapper />
    </>
  );
};
