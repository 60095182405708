import { createContext, useContext } from "react";

const GuideContext = createContext({});

export const GuideProvider = ({ children, value }) => {  
  return <GuideContext.Provider value={value}>{children}</GuideContext.Provider>;
};

export const useGuide = () => {
  const value = useContext(GuideContext);  
  if (!value) throw new Error("Must be used within a GuideProvider");
  return value;
};
