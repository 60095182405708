import { useRef, useState, useEffect } from "react";
import { ReactTyped } from "react-typed";
import { useMUD } from "../MUDContext";
import MainButton from "../components/MainButton";
import { GuideDisplay } from "./Game/Persistent/Guide";

import { gameStates } from "../config";
import ExtraAudio from "../ExtraAudio.js";

import { dispFactionName, getGuideText, textConst } from "../text";

import imgGuideChurch from "../assets/images/guide-Church.png";
import imgGuideRadical from "../assets/images/guide-Radical.png";
import imgGuideWolf from "../assets/images/guide-Wolf.png";

const imgGuide = {
  Church: imgGuideChurch,
  Radical: imgGuideRadical,
  Wolf: imgGuideWolf 
}

import "../assets/styles/player-creation.css";

const NAME_MAXLEN = 12;

export const PlayerCreation = () => {
  const {
    network: { tables, useStore },
    systemCalls: { addPlayer },
  } = useMUD();
  
  const Factions = useStore((state) => {
    const factions = Object.values(state.getRecords(tables.Fraction));    
    return factions;
  });

  const allPlayerNames = useStore(({getRecords}) => {
    const players = getRecords( tables.Player );
    const names = Object.values( players ).map( p => p.value.name.toLowerCase() );
    return names;
  })

  const [hasSelectedFaction, setHasSelectedFaction] = useState( false );

  const [factionState, setFactionState] = useState( ['Church', 'Radical', 'Wolf' ].sort((a, b) => 0.5 - Math.random())[0] );
  const [isNameAttempted, setIsNameAttempted] = useState( false );
  const [isNameValid, setIsNameValid] = useState( false );
  const [isNameTaken, setIsNameTaken] = useState( false );
  const [username, setUsername] = useState( '' );
  const nameRef = useRef(null);
  
  const handleFactionChange = ( e ) => {
    setFactionState( e.target.value );
  }

  const selectUsername = () => {
    setIsNameAttempted( true );
    setIsNameTaken( false );
    if( !nameRef.current.value ){
      setIsNameValid( false );
      nameRef.current.focus();
      return;
    }
    if( allPlayerNames.indexOf( nameRef.current.value.toLowerCase() ) > -1 ){
      setIsNameTaken( true );
      setIsNameValid( false );
      nameRef.current.focus();
      return;
    }

    const name = nameRef.current.value.substring( 0, NAME_MAXLEN );

    setUsername( name );
    setIsNameValid( true );
  }

  const createPlayer = () =>{
    if( factionState && !!username ){
      addPlayer( factionState, username )
    }
  }

  useEffect( () => {   
    if( !hasSelectedFaction ){
      ExtraAudio.setState( gameStates.FACTION );
    } else {
      ExtraAudio.setState( gameStates.NAME );
    }
  }, [ hasSelectedFaction] );

  return <>
    {(hasSelectedFaction) ? <div className="player-create--fango-img"></div> : '' }
    <div className="fango-grid">   
      {(hasSelectedFaction === false) ?
          <>          
          <div className="gi-m">
            <section className="player-create--choices">
              <label>
                <input type="radio" value="Church" checked={factionState === 'Church'} onChange={(e) => {
                  ExtraAudio.sfxSample('click');
                  handleFactionChange(e);
                }}/>
                <article className="player-create--faction fango-border">
                  {/* <img src={imgGuideChurch} alt="" /> */}
                  <h1>You are a monk...</h1>
                  <p>
                    ...looking to be welcomed into Fango's Monastery. As it is said to be, its monks possess power and influence. People fear them and they fear God, just like you. They control the chronicles, and with their blessing you’ll be able to pick any you like... 
                  </p>
                </article>
              </label>
              <label>
                <input type="radio" value="Radical" checked={factionState === 'Radical'} onChange={(e) => {
                  ExtraAudio.sfxSample('click');
                  handleFactionChange(e);
                }}/>
                <article className="player-create--faction fango-border">
                {/* <img src={imgGuideRadical} alt="" /> */}
                  <h1>You are a scholar...</h1>
                  <p>
                  ...and you have always challenged dominant narratives. You can alter minds with your words, gain traction for new ideas and question the status quo. An old mentor awaits you in Fango - he’ll help you access the chronicles through backdoor channels...
                  </p>
                </article>
              </label>
              <label>
                <input type="radio" value="Wolf" checked={factionState === 'Wolf'} onChange={(e) => {
                  ExtraAudio.sfxSample('click');
                  handleFactionChange(e);
                }}/>
                <article className="player-create--faction fango-border">
                  {/* <img src={imgGuideWolf} alt="" /> */}
                  <h1>You are a fool...</h1>
                  <p>
                    ...and always have been. You talk freely, you entertain and offend. The fame of your tales precede that of your name. New materials for your stories awaits in the chronicles, and you can beg your way into the monastery to access them...
                  </p>
                </article>
              </label>     
              <div className="player-choice-btn">
                <MainButton name={`Walk into Fango`} onClick={ (e) => { e.preventDefault(); setHasSelectedFaction(true) } } /> 
              </div>
            </section>
          </div>
          </>
      : 
        <>          
          <div className="gi-mr">
            <section className="player-create--row gi-mr--pb">
              <input 
                type="text" 
                ref={nameRef}
                className="player-create--name fango-border fango-main-button"
                onKeyDown={ (e) => {
                  ((nameRef.current.value.length >= NAME_MAXLEN && (e.which > 46)) || e.key === '&') ? e.preventDefault() : false;
                  if( e.key === 'Enter' && !isNameValid ){
                    selectUsername();
                  } 
                }}
                disabled={isNameValid}
                placeholder="Type Your Name"
              />
              {(!isNameValid) 
              ? 
                <>                  
                  <MainButton name={`Say your name`} onClick={ (e) => { e.preventDefault(); selectUsername() } } />
                </>
              : <>
                  <MainButton name={`Walk up to monastery`} onClick={ (e) => { e.preventDefault(); createPlayer() } } />
                </>
            }
            </section>
            <section className="gi-mr--g">
              <GuideDisplay                
                faction={factionState} 
                statement={
                  (isNameAttempted && !isNameValid)
                    ? (isNameTaken) 
                      ? getGuideText( factionState, textConst.NAMETAKEN, [{find:'<username>', replace: nameRef.current?.value}] ) 
                      : `What's your name?`
                    : (isNameValid) 
                        ? getGuideText( factionState, textConst.WELCOME2, [{find:'<username>', replace: nameRef.current?.value}]) 
                        : getGuideText( factionState, textConst.WELCOME1)
                } 
              />
            </section>            
          </div>
        </>
      }
    </div> 
  </>
}