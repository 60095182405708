import { _ } from "lodash";
import Line from "./components/Line";
import React from "react";

function ordinalize(n) {
  let ord = 'th';
  if (n % 10 == 1 && n % 100 != 11){
    ord = 'st';
  } else if (n % 10 == 2 && n % 100 != 12){
    ord = 'nd';
  }else if (n % 10 == 3 && n % 100 != 13){
    ord = 'rd';
  }
  return n + ord;
}

const GAME_DAY_LENGTH = 12000;//3600000;
const IRL_DAY_LENGTH = 86400000;


export const hasBeenMoreThanADay = ( t1:number, t2:number ) => t1 - t2 > GAME_DAY_LENGTH;
export const progressToADay = ( t1:number, t2:number ) => _.clamp( (t1 - t2) / GAME_DAY_LENGTH, 0, 1 );

export const hasBeenMoreThan24h = ( t1:number, t2:number ) => t1 - t2 > IRL_DAY_LENGTH;
export const progressTo24h = ( t1:number, t2:number ) => _.clamp( (t1 - t2) / IRL_DAY_LENGTH, 0, 1 );
export const timestampToAgeInDays = ( t: number ) => {
  const now = new Date().getTime();
  const delta = now - t;
  return Math.floor( delta / IRL_DAY_LENGTH );
}

export const toWorldTime = ( t:number ) => {
  const months = ['January','February','March','April','May','June','July','August','September','October','November','December'];
  const d = new Date(t);
  return `${ordinalize(d.getDay())} of ${ months[d.getMonth()] } in the year ${d.getFullYear() - 970 }`
}



export const formatMultilineForDisplay = ( text ) => {  
  const splitter = (text.indexOf('<LN>') > -1 ) ? '<LN>' : '\n';
  return text.split( splitter ).map( (line, i) => React.createElement(Line, {content: line, key: i }) );
}