/*
 * Create the system calls that the client can use to ask
 * for changes in the World state (using the System contracts).
 */

import { Has, HasValue, runQuery } from "@latticexyz/react";
import { Hex, createPublicClient, Address } from "viem";
import { SetupNetworkResult } from "./setupNetwork";
import { uuid } from "@latticexyz/utils";

import { secretCfg } from "../config.js";

export type SystemCalls = ReturnType<typeof createSystemCalls>;

export function createSystemCalls(
  /*
   * The parameter list informs TypeScript that:
   *
   * - The first parameter is expected to be a
   *   SetupNetworkResult, as defined in setupNetwork.ts
   *
   *   Out of this parameter, we only care about two fields:
   *   - worldContract (which comes from getContract, see
   *     https://github.com/latticexyz/mud/blob/main/templates/react/packages/client/src/mud/setupNetwork.ts#L63-L69).
   *
   *   - waitForTransaction (which comes from syncToRecs, see
   *     https://github.com/latticexyz/mud/blob/main/templates/react/packages/client/src/mud/setupNetwork.ts#L77-L83).
   *
   * - From the second parameter, which is a ClientComponent,
   *   we only care about Counter. This parameter comes to use
   *   through createClientComponents.ts, but it originates in
   *   syncToRecs
   *   (https://github.com/latticexyz/mud/blob/main/templates/react/packages/client/src/mud/setupNetwork.ts#L77-L83).
   */
  { tables, useStore, walletClient, worldContract, waitForTransaction, publicClient }: SetupNetworkResult,
) {
  const addPlayer = async ( faction:String, name:String ) => {   
    const factions = useStore.getState().getRecords(tables.Fraction);    
    const key = Object.values(factions).find(( f ) => f.value.fractionName === faction )?.key?.counterValue;
    
    if( !key ) return new Error("invalid faction");    
    
    const tx = await worldContract.write.addPlayer([key, name]);    
    await waitForTransaction( tx );    
  }

  const rollForChronicle = async ( seedId:number ) => {        
    //TODO: will eventually have to know player's faction and roll right function here e.g: 
    //const tx = await worldContract.write.SeedChurch([seedId]);    
    try {
      const tx = await worldContract.write.universalSeed([seedId]);    
      await waitForTransaction( tx );    
    } catch ( e ){
      // console.log(e);
      if( e.shortMessage.indexOf( 'PLAYER STILL IN TIMEOUT' ) ){
        return 'timeout';
      }      
    }
  }

  const addStory = async ( story:string ) => {
    const tx = await worldContract.write.addStory([story]);
    await waitForTransaction( tx );
    if( story.toLowerCase().indexOf( secretCfg.pw1.toLowerCase() ) !== -1 ){
      const tx2 = await worldContract.write.secretOne();
      await waitForTransaction( tx2 );
    }
    if( story.toLowerCase().indexOf( secretCfg.pw2.toLowerCase() ) !== -1 ){
      const tx3 = await worldContract.write.secretTwo();
      await waitForTransaction( tx3 );
    }
    if( story.toLowerCase().indexOf( secretCfg.pw3.toLowerCase() ) !== -1 ){
      const tx4 = await worldContract.write.secretThree();
      await waitForTransaction( tx4 );
    }
  }

  const addVote = async ( storyId:Address, vote:boolean ) => { 
    const tx = await worldContract.write.addVote([storyId, vote]);
    await waitForTransaction( tx );
  }

  const forkStory = async ( parentHash:Address, story:string ) => {
    const tx = await worldContract.write.forkStory( [parentHash, story] );
    await waitForTransaction( tx );
  }

  return {
    addPlayer,
    rollForChronicle,
    addStory,
    addVote,
    forkStory
  };
}
