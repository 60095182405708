import { useState } from "react";
import { useMUD } from "../../../MUDContext";
import MainButton from "../../../components/MainButton";

import { hasBeenMoreThanADay } from "../../../utils";

import { gameStates } from "../../../config.js";

export const StateSelector = ({onSelect}) => {
  const {
    network: { tables, useStore, playerEntity },    
  } = useMUD();
  
  const isInitiallyRollTimeout = useStore( ({getValue}) => {
    const player = getValue(tables.Player, {key: playerEntity});
    const playerTimes = getValue( tables.PlayerTimes, {key: playerEntity});
    if( !playerTimes ) return false;
    const now = new Date().getTime();
    
    return  ! hasBeenMoreThanADay( now, parseInt( playerTimes.lastRoll ) * 1000 );
  })

  const [gameState, setGameState] = useState( gameStates.HOME );
  const playerHasSeed = useStore( ({ getValue }) => {
    const player = getValue(tables.Player, {key: playerEntity});
    return !!player?.currentSeedNumbers[0];
  });

  const playerHasWritten = useStore( ({ getValue }) => {
    const player = getValue(tables.Player, {key: playerEntity});
    return !!player?.hasWritten;
  });

  return <>
      <MainButton 
        disabled={ gameState === gameStates.READ}
        name={`To the Story Doors`} 
        onClick={() => {
          setGameState( gameStates.READ );
          onSelect( gameStates.READ );
        }
      }/>
      <MainButton 
        disabled={ 
          ( !playerHasSeed && gameState === gameStates.ROLL) 
          || (isInitiallyRollTimeout && !playerHasSeed ) 
          || (playerHasSeed && gameState === gameStates.WRITE) 
        }
        name={`To the Library`} 
        onClick={() => {
          if( isInitiallyRollTimeout || playerHasSeed ){
            setGameState( gameStates.WRITE );
            onSelect( gameStates.WRITE );
          } else {
            setGameState( gameStates.ROLL );
            onSelect( gameStates.ROLL );
          }
        }}/>
      {(playerHasWritten) ? <MainButton 
        disabled={ 
          gameState === gameStates.INN        
        }
        name={`To the Inn`} 
        onClick={() => {
          setGameState( gameStates.INN );
          onSelect( gameStates.INN );
      }}/> : ''}
      {/* {(playerHasSeed ) 
        ?
          <MainButton 
            disabled={ gameState === gameStates.WRITE}
            name={`Continue writing`} 
            onClick={() => {
              setGameState( gameStates.WRITE );
              onSelect( gameStates.WRITE );
            }}
          />
        : ''
      } */}
  </>
}