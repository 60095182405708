import "../assets/styles/clock.css";


export const ClockInterface = ({className="", progress=1}) => {
  return <div className={`fango-clock-interface ${className}`}>
    <Clock progress={progress} />
  </div>
}

export const Clock = ({progress=1}) => {
  const FRAMECOUNT = 24;
  return (
      <div 
        className="fango-clock"
        style={{
          opacity: (progress >= 1) ? 0 : 1
        }}
      >
        <div 
          className="clock-strip"
          style={{
            left: `${ (Math.floor(progress * FRAMECOUNT) / FRAMECOUNT) * (FRAMECOUNT * -100) }%`
          }}
        ></div>
      </div>
  );
}