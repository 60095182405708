import { useEffect, useState } from "react";
import { useMUD } from "../../../MUDContext";
import { useGuide } from "../../GuideContext";
import { getGuideText, textConst } from "../../../text";

export const StateHome = ({shouldRender}) => {
  const {
    network: { tables, useStore, playerEntity },    
  } = useMUD();

  const [hasTriggeredIntroText, setHasTriggeredIntroText] = useState(false);

  const username = useStore( ({ getValue }) => {    
    const player = getValue(tables.Player, {key: playerEntity});
    return player.name;
  });

  const playerFaction = useStore( ({ getValue }) => {    
    const player = getValue(tables.Player, {key: playerEntity});
    const faction = getValue(tables.Fraction, {counterValue: player.fraction});
    return faction.fractionName;
  });

  const secretHolder = useStore( ({ getRecords}) => {
    const players = Object.values( getRecords( tables.Player ) )
      .map( ( record ) => {
        return record.value;
      })
      .filter( ( record ) => {
        return record.wonAt > 0;
      })
      .sort( (a, b) => {
        return parseInt(b.wonAt) - parseInt(a.wonAt);
      });
    if( players.length > 0 ){
      return players[0].name;
    }
    return false;
  });

  const {guideState, setGuideState} = useGuide();
  useEffect(() => { 
    if( shouldRender ){   
      if( !hasTriggeredIntroText ){   
        setGuideState({
          ...guideState,
          statement: getGuideText( 
            playerFaction, 
            textConst.INTROAREA, 
            [
              {find: '<username>', replace: username},
              {find: '<latestsecretholder>', replace: secretHolder}
            ] )
        })
      } else {
        // setGuideState({
        //   ...guideState,
        //   statement: ''
        // })
      }
      setHasTriggeredIntroText( true )
    }
  },[ shouldRender ]);
  return <>
    <div 
      className="game-state game-state__home" 
      style={{
        display: (shouldRender) ? 'block' : 'none'
      }}
    >
      
    </div>
  </>
}