import { useState, useEffect, useRef } from "react";
import { useMUD } from "../../../MUDContext";
import MainButton from "../../../components/MainButton";
import { useGuide } from "../../GuideContext";
import { getGuideText, textConst } from "../../../text";
import { formatMultilineForDisplay } from "../../../utils";

import  "../../../assets/styles/state-write.css";

const ForkChurch = ({
  baseStory, 
  shouldRender, 
  onForkComplete = ( fork ) => {}, 
  onClose = () => {} 
}) => {
  const [find, setFind] = useState('');
  const [fork, setFork] = useState( baseStory )
  const [replace, setReplace] = useState('');
  const [hasRunReplace, setHasRunReplace] = useState( false );

  const {guideState, setGuideState} = useGuide();
  
  useEffect(() => { 
    if( shouldRender ){
      setGuideState({
        ...guideState,
        statement: getGuideText( 'Church', textConst.FORKINSTRUCTION )
      })
    }
  },[ shouldRender ]);

  return <>
    <div className="story-full story-fork">
      <div className="story-full--content">
        <section className="story-full--story">
          <div className="story-area">
            {formatMultilineForDisplay( fork )}
          </div>
        </section>
        <div className="fork-functions fork-functions__church">
          <span>Replace </span>
          <input 
            type="text"
            value={ find }
            onChange={ (e) => setFind( e.target.value.replace(' ', '') ) }
            onClick={ ()=> { ExtraAudio.sfxSample('click') }}
          /> 
          <span> with </span>
          <input
            type="text"
            value={ replace }
            onChange={ (e) => setReplace( e.target.value.replace(' ', '') ) }
            onClick={ () => { ExtraAudio.sfxSample('click') }}
          />
        </div>
      </div>
      <section className="story-full--actions">
        <MainButton 
          name="Replace word" 
          onClick={ () => {
            if( find === replace ) return;
            const result = baseStory.replace(
              RegExp( `\\b${find}\\b`, 'gi' ),
              replace
            );
            setFork( result );
            setHasRunReplace( true );
          }}
        />
        <MainButton 
          name="Submit altered story"
          className="fork-submit-button" 
          disabled={ !hasRunReplace }
          onClick={ () => {
            onForkComplete( fork );
          }}
        />
        <MainButton name="Close Story" onClick={ onClose }/>
      </section>
    </div>
  </>
}

const ForkRadical = ({
  baseStory, 
  shouldRender, 
  onForkComplete = ( fork ) => {}, 
  onClose = () => {}
}) => {
  const [fork, setFork] = useState( baseStory );
  const {guideState, setGuideState} = useGuide();
  const textAreaRef = useRef();
  
  const allowedKeys = [
    "ArrowLeft",
    "ArrowRight",
    "ArrowUp",
    "ArrowDown",
    "Backspace",
    "Delete"
  ];

  const keyNotAllowed = ( key ) => {
    return allowedKeys.indexOf( key ) === -1;
  }

  useEffect(() => { 
    if( shouldRender ){
      setGuideState({
        ...guideState,
        statement: getGuideText( 'Radical', textConst.FORKINSTRUCTION )
      })
    }
  },[ shouldRender ]);

  return <div className="story-full story-fork">
    <div className="story-full--content">
      <section className="story-full--story">
        <div className="story-area">      
          <textarea
            ref={textAreaRef}
            style={{userSelect: "none"}}
            value={ fork }
            onChange={ (e) => { setFork( e.target.value ) } }
            onKeyDown={ (e) => { ( keyNotAllowed(e.key) || fork.length <= Math.floor(baseStory.length/2) ) ? e.preventDefault() : false } }
            onSelect={ (e) => { textAreaRef.current.selectionStart = textAreaRef.current.selectionEnd }}
            onClick={ ()=>{
              ExtraAudio.sfxSample('click');
            }}
            autoFocus
          ></textarea>
        </div>
      </section>
      <div className="fork-functions">
        {baseStory.length - fork.length} of {Math.floor(baseStory.length/2)} possible characters deleted
      </div>
    </div>
    <section className="story-full--actions">
      <MainButton 
        className="fork-submit-button"
        name="Submited altered story"
        onClick={ () => {
          onForkComplete( fork );
        }}
        disabled={baseStory.length === fork.length}
      />
      <MainButton name="Close story" onClick={ onClose }/>
    </section>
  </div>
}

const ForkInterface = ({
  baseStory,
  onForkComplete=()=>{},
  onClose=()=>{},
  shouldRender
}) => {
  const {
    network: { tables, useStore, playerEntity },
    systemCalls: { forkStory }
  } = useMUD();

  const playerFaction = useStore( ({ getValue }) => {    
    const player = getValue(tables.Player, {key: playerEntity});
    const faction = getValue(tables.Fraction, {counterValue: player.fraction});
    return faction.fractionName;
  });
  
  const submitFork = ( fork ) => {    
    forkStory( baseStory.key.counterValue, fork );
    onForkComplete( fork );
  }

  return <>
    <div className="write-interface fork-interface">
      {(typeof baseStory?.value?.data !== 'undefined' && playerFaction === 'Church' ) 
        ? <ForkChurch 
            baseStory={ baseStory?.value?.data }
            onForkComplete={ submitFork }
            shouldRender={shouldRender}
            onClose={ onClose }
          />
        : ''
      }
      {(typeof baseStory?.value?.data !== 'undefined' && playerFaction === 'Radical') 
        ? <ForkRadical 
            baseStory={ baseStory?.value?.data }
            onForkComplete={ submitFork }
            shouldRender={shouldRender}
            onClose={ onClose }
          />
        : ''
      }
       {(typeof baseStory?.value?.data !== 'undefined' && playerFaction === 'Wolf') 
        ? 'Nothing here'
        : ''
      }
    </div>
  </>
}

export const StateFork = ({
  shouldRender,
  baseStory,
  onForkComplete = () => {},
  onClose = () => {}
}) => {
  const {
    network: { tables, useStore, playerEntity },    
  } = useMUD();

  const player = useStore( ({ getValue }) => {
    return getValue(tables.Player, {key: playerEntity});    
  });
  
  return <>
    <div
      className="game-state game-state__fork" 
      style={{
        display: (shouldRender) ? 'block' : 'none'
      }}
    >
      {(baseStory) 
        ? <ForkInterface 
            onForkComplete={() => { 
              ExtraAudio.sfxSample('pin');
              onForkComplete();
            }}
            baseStory={baseStory}
            shouldRender={shouldRender}
            onClose={onClose}
        /> 
        : 'Go to read to choose an existing story to fork.'
      }
    </div>
  </>
}