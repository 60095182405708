import '../assets/styles/popup.css';

import ExtraAudio from "../ExtraAudio.js";

export const PopUp = ({ className='', children, onClick=()=>{} }) => {
    return <div 
        className={`pop-up ${className}`} 
        onClick={() => {
            ExtraAudio.sfxSample('click');
            onClick();
        }}
    >
        <div className="visible-inner fango-border">
            {children}
        </div>
    </div>
  }