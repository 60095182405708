import { useState, useEffect } from "react";
import { shuffle } from "lodash";
import { useMUD } from "../../../MUDContext";

import { secretCfg } from "../../../config.js";

import { useGuide } from "../../GuideContext";

import MainButton from "../../../components/MainButton";
import { ShowSecret } from "../../../components/ShowSecret";
import { GameWon } from "../../../components/GameWon";
import { hasBeenMoreThanADay } from "../../../utils.js";

import { textConst, getGuideText, getChronicleTypeComment } from "../../../text";

import ExtraAudio from "../../../ExtraAudio.js";

import  "../../../assets/styles/state-roll.css";

const RollInterface = ({seeds, selected=false, onChange=()=>{}}) => {
  const {
    network: { tables, useStore, playerEntity }
  } = useMUD();

  const allSeeds = Array.from({ length: 1000 }, (value, index) => index + 1 );
  const randomAvailableSeed = seeds[Math.floor(Math.random() * seeds.length)].value.seed;
  const [currentChecked, setCurrentChecked] = useState( (selected) ? selected : randomAvailableSeed);

  const player = useStore( ({getValue}) => {
    const player = getValue( tables.Player, {key: playerEntity} );
    return player;
  })
  
  const secretSeeds = Array.from({length: player.secret}, (value, index) => index );
  const [showSecret, setShowSecret] = useState( false );
  const [showWon, setShowWon] = useState( false );

  useEffect( () => {
    if( selected ){
      setCurrentChecked( selected )
    }
  }, [selected])

  useEffect( () => {
    onChange( currentChecked );
  }, [])

  return <>
    <div className="roll-interface fango-border">
      {allSeeds.map( (id) => {
        const available = seeds.find( ( seed ) => {
          return seed.value.seed === id;
        });
        return <label className={`roll-item${!available ? ' disabled':  ''}`}><input       
          type="radio" 
          value={id} 
          disabled={!available} 
          onChange={ ( e ) => { 
            ExtraAudio.sfxSample('click');
            setCurrentChecked( parseInt(e.target.value) );
            onChange( e.target.value );        
          }}
          checked={ parseInt(id) === parseInt(currentChecked) }
        ></input>
          <div className="seed-selection">
            <span>{id}</span>
          </div>
        </label>
      })}
      {secretSeeds.map( (id) => {    
        return <label 
          className={`roll-item`}        
        >       
          <div className="seed-selection">          
            <button           
              type="button" 
              onClick={() => {
                ExtraAudio.sfxSample('click');
                setShowSecret( id );               
              }}
            >
              {secretCfg.symbols[id]}
            </button>
          </div>
        </label>
      })} 
    </div>
    {(showSecret !== false) 
      ? <ShowSecret 
          onClose={()=>{
            setShowSecret( false );            
            if( showSecret === 2 ){
              setShowWon( true );
            }
          }}
          text={secretCfg.texts[showSecret]}
        />
      : ''
    }
    {(showWon) ? <GameWon onClick={()=>{ setShowWon( false ) }}/> : '' }
  </>
}

/*pick from all numbers without info */
const RollChurch = ( {seeds, onChange=()=>{}} ) => {  
  return <RollInterface seeds={seeds} onChange={onChange} />
}



/* get 3 numbers to select from */
const RollRadical = ( {seeds, onChange=()=>{}} ) => {
  const [choices, setChoices] = useState(shuffle( seeds ).slice( 0, 3 ));  
  return  <RollInterface seeds={choices} onChange={onChange} />
}

/* keep rolling until you get a number you like */
const RollWolf = ( {seeds, onChange=()=>{}} ) => {
  const [selectedSeed, setSelectedSeed] = useState( [shuffle( seeds )[0]] );
  return (
    <>
      <RollInterface
        selected={selectedSeed[0].value.seed}
        seeds={selectedSeed} 
        onChange={onChange} 
      />
      <MainButton
        className="roll-wolf-shuffle-btn"
        name={`Grab a different one`}            
        onClick={ () => {
          const newId = shuffle( seeds )[0];
          setSelectedSeed( [newId] );
          onChange(newId.value.seed);
        } }              
      />
    </>
  )
}

export const StateRoll = ({
  shouldRender,
  onRollComplete
}) => {
  /* TODO: why is this the hash for an unowned seed? Is there a smarter way of doing this? */
  const UNOWNED_SEED_HASH = "0xc5d2460186f7233c927e7db2dcc703c0e500b653ca82273b7bfad8045d85a470";
  const {
    network: { tables, useStore, playerEntity },
    systemCalls: { rollForChronicle },
  } = useMUD();

  const [seedId, setSeedId] = useState( -1 );
  const [isTimeout, setIsTimeout] = useState(false);
  const [hasRolledSuccessfully, setHasRolledSuccessfully] = useState(false);
  const [hasShownRollInstruction, setHasShownRollInstruction] = useState(false);

  const isInitiallyTimeout = useStore( ({getValue}) => {
    const player = getValue(tables.Player, {key: playerEntity});
    const playerTimes = getValue( tables.PlayerTimes, {key: playerEntity});
    if( !playerTimes ) return false;
    const now = new Date().getTime();
    const notTimeout = hasBeenMoreThanADay( now, parseInt( playerTimes.lastRoll ) * 1000 );
    return !notTimeout;
  })

  const player= useStore( ({ getValue, getRecords }) => {
    /* TODO: should be able to do this: */
    const player = getValue(tables.Player, {key: playerEntity});
    return player;
  });

  const playerFaction = useStore( ({ getValue }) => {    
    const player = getValue(tables.Player, {key: playerEntity});
    const faction = getValue(tables.Fraction, {counterValue: player.fraction});
    return faction.fractionName;
  });

  const playerHasSeed = useStore( ({ getValue, getRecords }) => {
    /* TODO: should be able to do this: */
    const player = getValue(tables.Player, {key: playerEntity});
    // return !!player.currentSeedNumbers[0];
    /* but currently the seed remains in the player table so we do: */
    const playerCurrentSeed = player.currentSeedNumbers[0];    
    const allStoriesByPlayer = Object.values(getRecords( tables.StoryPart )).filter( (story) => story.value.author === playerEntity );
  });

  const secretHolder = useStore( ({ getRecords}) => {
    const players = Object.values( getRecords( tables.Player ) )
      .map( ( record ) => {
        return record.value;
      })
      .filter( ( record ) => {
        return record.wonAt > 0;
      })
      .sort( (a, b) => {
        return parseInt(b.wonAt) - parseInt(a.wonAt);
      });
    if( players.length > 0 ){
      return players[0].name;
    }
    return false;
  });

  const availableStorySeeds = useStore( ({getRecords}) => {
    const seeds = getRecords(tables.StorySeed);    
    return Object.values(seeds).filter( (seed) => seed.value.ownership === UNOWNED_SEED_HASH );
  });

  const seedsAvailable = availableStorySeeds.length > 0;

  const randomSeedId = () => {
    return availableStorySeeds[ Math.floor( Math.random() * availableStorySeeds.length ) ].value.seed;
  }

  const {guideState, setGuideState} = useGuide();
  useEffect(() => { 
    if( shouldRender ){

      if( !hasShownRollInstruction ){
        setGuideState({
          ...guideState,
          statement: (isInitiallyTimeout) 
            ? getGuideText( 
                playerFaction, 
                textConst.ROLLTIMEOUT, 
                [{find: '<username>', replace: player.name}] 
              ) 
            : getGuideText( 
                playerFaction, 
                textConst.ROLLINSTRUCTION, 
                [
                  {find: '<username>', replace: player.name},
                  {find: '<latestsecretholder>', replace: secretHolder}
                ] 
              )
        })
      } else {
        setGuideState({
          ...guideState,
          statement: ''
        })
      }

      setHasShownRollInstruction( true );
    }
  },[ shouldRender ]);

  if( isInitiallyTimeout ){
    return <div 
      className="game-state game-state__roll" 
      style={{
        display: (shouldRender) ? 'block' : 'none'
      }}
    ></div>
  }
  return <>
    <div 
      className="game-state game-state__roll" 
      style={{
        display: (shouldRender) ? 'block' : 'none'
      }}
    >    
        {( !playerHasSeed && playerFaction === 'Church')
          ? <RollChurch 
              seeds={availableStorySeeds}
              onChange={(id) => {
                setSeedId(id);
              }}
            />
          : ''
        }
        {( !playerHasSeed && playerFaction === 'Radical')
          ? <RollRadical 
              seeds={availableStorySeeds}
              onChange={(id) => {
                setSeedId(id);
              }}
            />
          : ''
        }
        {( !playerHasSeed && playerFaction === 'Wolf')
          ? <RollWolf
              seeds={availableStorySeeds}
              onChange={(id) => {
                setSeedId(id);
              }}
            />
          : ''
        }
        {(playerHasSeed) 
          ? 'You already have a chronicle, write the story for that.' 
          : (seedsAvailable) 
            ? <><MainButton
              className="roll-select-btn"
              disabled={seedId === -1} 
              name={`Open chronicle${(seedId > -1) ? ` ${seedId}` : ``}`}            
              onClick={ () => { 
                  rollForChronicle( seedId )
                    .then( ( result ) => {
                      if( result === 'timeout' ){
                        console.log( 'You must wait until tomorrow to roll again' );
                        setIsTimeout( true );
                        setHasRolledSuccessfully(false);
                      } else {
                        setIsTimeout( false );
                        setHasRolledSuccessfully(true);
                        ExtraAudio.sfxSample('page');
                        onRollComplete();
                      }
                    });
              }}              
            />
            </>
            : 'All chronicles have been claimed. Fork an existing story instead'
        }
    </div>
  </>
}