import { useEffect, useState } from "react";
import { useMUD } from "../../../MUDContext";
import { useGuide } from "../../GuideContext";
import { getGuideText, textConst } from "../../../text";
import MainButton from "../../../components/MainButton";
import ButtonStyleText from "../../../components/ButtonStyleText";

import "../../../assets/styles/state-inn.css";
import { useLeaderboard } from "../../LeaderboardContext";

import ExtraAudio from "../../../ExtraAudio.js";

const innPositionState = {
  'DOOR': 0,
  'MONK': 1,
  'SCHOLAR': 2,
  'FOOL': 3
};

import imgUnseated from "../../../assets/images/inn-keeper.gif";

import imgMonkTableLosing from "../../../assets/images/monks-table-loosing.gif";
import imgMonkTableWinning from "../../../assets/images/monks-table-winning.gif";
import imgScholarTableLosing from "../../../assets/images/scholars-table-loosing.gif";
import imgScholarTableWinning from "../../../assets/images/scholars-table-winning.gif";
import imgFoolTableLosing from "../../../assets/images/fools-table-loosing.gif";
import imgFoolTableWinning from "../../../assets/images/fools-table-winning.gif";

const getInnStateName = ( state ) => {
  const keys = Object.keys( innPositionState );
  const values = Object.values( innPositionState );
  return keys[ values.indexOf( state )];
}

export const StateInn = ({shouldRender}) => {
  const {
    network: { tables, useStore, playerEntity },    
  } = useMUD();

  const [hasShownInnIntro, setHasShownInnIntro] = useState(false);
  const [innPosition, setInnPosition] = useState( innPositionState.DOOR )
  const [drinkCount, setDrinkCount] = useState( 0 );
  const [isDrunk, setIsDrunk] = useState( false );
  const [convoState, setConvoState] = useState([]);
  const [convoImageState, setConvoImageState] = useState( imgUnseated );
  const DRUNK_COUNT = 10;

  const {guideState, setGuideState} = useGuide();
  const {leaderboardState, setLeaderboardState} = useLeaderboard();
  
  const secretHolder = useStore( ({ getRecords}) => {
    const players = Object.values( getRecords( tables.Player ) )
      .map( ( record ) => {
        return record.value;
      })
      .filter( ( record ) => {
        return record.wonAt > 0;
      })
      .sort( (a, b) => {
        return parseInt(b.wonAt) - parseInt(a.wonAt);
      });    
    if( players.length > 0 ){
      return players[0].name;
    }
    return false;
  })

  const username = useStore( ({ getValue }) => {    
    const player = getValue(tables.Player, {key: playerEntity});
    return player.name;
  });

  const playerFaction = useStore( ({ getValue }) => {    
    const player = getValue(tables.Player, {key: playerEntity});
    const faction = getValue(tables.Fraction, {counterValue: player.fraction});
    return faction.fractionName;
  });

  useEffect(() => { 
    if( shouldRender ){     
      if( !hasShownInnIntro ){
        setGuideState({
          ...guideState,
          statement: getGuideText( playerFaction, textConst.INNINTRO )
        })
      } else {
        setGuideState({
          ...guideState,
          statement: ''
        })
      }

      setHasShownInnIntro( true );
    } else {
      setInnPosition( innPositionState.DOOR );
      setConvoImageState( imgUnseated );
      setDrinkCount( 0 );
    }
  },[ shouldRender ]); 

  useEffect(() => {
    if( !shouldRender ) return;    
    let conversationSet = -1;
    if( innPosition === innPositionState.MONK ){
      if( leaderboardState.ranking[0].faction === 'monk' ){
        conversationSet = (isDrunk) 
          ? textConst.DRUNKINNMONKMONK 
          : (secretHolder) 
            ? textConst.SECRETHOLDERINNMONKMONK
            : textConst.INNMONKMONK;
        setConvoImageState( imgMonkTableWinning );
      } else if( leaderboardState.ranking[0].faction === 'scholar' ){
        conversationSet = (isDrunk) 
          ? textConst.DRUNKINNMONKSCHOLAR 
          : (secretHolder) 
           ? textConst.SECRETHOLDERINNMONKSCHOLAR
           : textConst.INNMONKSCHOLAR;
        setConvoImageState( imgMonkTableLosing );
      } else if( leaderboardState.ranking[0].faction === 'fool' ){
        conversationSet = (isDrunk) 
          ? textConst.DRUNKINNMONKFOOL 
          : (secretHolder) 
            ? textConst.SECRETHOLDERINNMONKFOOL
            : textConst.INNMONKFOOL;
        setConvoImageState( imgMonkTableLosing );
      }
    }

    if( innPosition === innPositionState.SCHOLAR ){
      if( leaderboardState.ranking[0].faction === 'monk' ){
        conversationSet = (isDrunk) 
          ? textConst.DRUNKINNSCHOLARMONK 
          : (secretHolder) 
            ? textConst.SECRETHOLDERINNSCHOLARMONK
            : textConst.INNSCHOLARMONK;
        setConvoImageState( imgScholarTableLosing );
      } else if( leaderboardState.ranking[0].faction === 'scholar' ){
        conversationSet = (isDrunk) 
          ? textConst.DRUNKINNSCHOLARSCHOLAR 
          : (secretHolder) 
            ? textConst.SECRETHOLDERINNSCHOLARSCHOLAR
            : textConst.INNSCHOLARSCHOLAR;
        setConvoImageState( imgScholarTableWinning );
      } else if( leaderboardState.ranking[0].faction === 'fool' ){
        conversationSet = (isDrunk) 
          ? textConst.DRUNKINNSCHOLARFOOL 
          : (secretHolder) 
            ? textConst.SECRETHOLDERINNSCHOLARFOOL
            : textConst.INNSCHOLARFOOL;
        setConvoImageState( imgScholarTableLosing );
      }
    }

    if( innPosition === innPositionState.FOOL ){
      if( leaderboardState.ranking[0].faction === 'monk' ){
        conversationSet = (isDrunk) 
          ? textConst.DRUNKINNFOOLMONK 
          : (secretHolder) 
            ? textConst.SECRETHOLDERINNFOOLMONK
            : textConst.INNFOOLMONK;
        setConvoImageState( imgFoolTableLosing );
      } else if( leaderboardState.ranking[0].faction === 'scholar' ){
        conversationSet = (isDrunk) 
          ? textConst.DRUNKINNFOOLSCHOLAR 
          : (secretHolder) 
            ? textConst.SECRETHOLDERINNFOOLSCHOLAR
            : textConst.INNFOOLSCHOLAR;
        setConvoImageState( imgFoolTableLosing );
      } else if( leaderboardState.ranking[0].faction === 'fool' ){
        conversationSet = (isDrunk) 
          ? textConst.DRUNKINNFOOLFOOL 
          : (secretHolder) 
            ? textConst.SECRETHOLDERINNFOOLFOOL
            : textConst.INNFOOLFOOL;
        setConvoImageState( imgFoolTableWinning );
      }
    }

    const conversation = ( conversationSet === -1 ) 
      ? 
        [''] 
      : 
        getGuideText( 
          playerFaction, 
          conversationSet, 
          [
            {find: '<topplayer>', replace: leaderboardState.ranking[0].author.name },
            {find: '<worstplayer>', replace: leaderboardState.ranking[leaderboardState.ranking.length-1].author.name },
            {find: '<latestsecretholder>', replace: secretHolder },
            {find: '<username>', replace: username },
          ]
        ).sort(() => Math.random() - 0.5);
    
    setGuideState({
      ...guideState,
      statement: conversation
    })
  }, [innPosition, isDrunk]);

  useEffect( () => {
    if( drinkCount >= DRUNK_COUNT ){
      setIsDrunk( true );
    } else {
      setIsDrunk( false );
    }
  }, [drinkCount])

  return <>
    <div 
      className="game-state game-state__inn" 
      style={{
        display: (shouldRender) ? 'block' : 'none'
      }}
    >
      <section className={`inn-wrapper inn__${getInnStateName(innPosition)}`}>         
          <div className="inn-fakeguide">            
            <div className="fakelabel">
              <ButtonStyleText text={(innPosition === innPositionState.DOOR ) ? `Innkeeper`: `(${drinkCount} beers)`} />
            </div>
            <div className="fango-guide--box">
              <div className="fango-guide--avatar">
                <img src={convoImageState} />
              </div>
            </div>
          </div>
          <section className="inn-actions">      
            <MainButton 
              name="Sit with monks"
              disabled={innPosition === innPositionState.MONK}
              onClick={() => {setInnPosition( innPositionState.MONK )}}
            />
            <MainButton 
              name="Sit with scholars"
              disabled={innPosition === innPositionState.SCHOLAR} 
              onClick={() => {setInnPosition( innPositionState.SCHOLAR )}}
            />
            <MainButton 
              name="Sit with fools" 
              disabled={innPosition === innPositionState.FOOL}
              onClick={() => {setInnPosition( innPositionState.FOOL )}}
            />
            {(innPosition !== innPositionState.DOOR) 
              ?
                <MainButton 
                  name={ (drinkCount < DRUNK_COUNT) ? "Drink!" : "You are drunk" }
                  disabled={innPosition === innPositionState.DOOR || drinkCount >= DRUNK_COUNT }
                  onClick={() => { 
                    ExtraAudio.sfxSample('drink')
                    setDrinkCount( drinkCount + 1 );
                  }}
                />
              :
                ''
            }
          </section>
      </section>
    </div>
  </>
}